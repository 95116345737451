<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>定金尾款幅度设置</div>
                </div>
                <!--<div class="admin_main_block_right">
                    <div><el-button type="primary" icon="el-icon-menu" >定金尾款幅度设置</el-button></div>
                </div>-->
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label-width="130px" label="定金幅度" prop="dingj" :rules="[{required:true,message:'定金幅度不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入定金幅度" v-model="info.dingj"></el-input></el-form-item>
                    <el-form-item label-width="130px" label="尾款幅度" prop="weik" :rules="[{required:true,message:'尾款幅度不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入尾款幅度" v-model="info.weik"></el-input></el-form-item>
                    <el-form-item label-width="130px" >
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          info:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        submitForm:function(e){
            this.$refs[e].validate(res=>{
                if(res){
                    // Http 请求
                    this.$post(this.$api.dingjwkPublicConfig,this.info).then(postRes=>{
                        if(postRes.code == 200){
                            this.$message.success("编辑成功");
                            this.get_wecaht_public_config();
                        }else{
                            this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_wecaht_public_config(){
            this.$get(this.$api.dingjwkPublicConfig).then(res=>{
                if(res.data != null){
                    this.info  = res.data;
                }
            });
        },
    },
    created() {
        this.get_wecaht_public_config();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>